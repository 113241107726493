// eslint-disable-next-line import/no-anonymous-default-export
export default {
    home: {
        title: 'Chào mừng tới Viblo!',
        content: 'Bắt đầu một bài viết nào',
    },
    "all": "Tất cả",
    "applyFor": "Ứng tuyển vị trí",
    "apply": "Ứng tuyển",
    "searchResult": "kết quả tìm kiếm",
    "relatedJobs": "Công việc liên quan",
    "benefits": "Phúc lợi",
    "assignTo": "Người phụ trách",
    "noInfo": "Không có thông tin",
    "generalInfo": "Thông tin chung",
    "hotJob": "Gấp",
    "filterByCriteria": "Chọn lọc theo tiêu chí",
    "jobName": "Tên công việc",
    "jobLocation": "Nơi làm việc",
    "salary": "Thoả thuận",
    "job": "Vị trí",
    "jobListTitle": "Vị trí tuyển dụng",
    modalApplyNow: {
        "searchCode": "Mã số tra cứu ứng viên vủa bạn là:",
        "searchCodeBody": "Vui lòng lưu lại để không đánh mất mã tra cứu",
        "research": "Tra cứu",
        "here": "tại đây",
        "researchBody": "để theo dõi quá trình ứng tuyển"
    },
    applyNow: {
        "success": "Nộp đơn thành công!",
        "successBody": "Cảm ơn bạn đã quan tâm đến công ty.",
        "fail": "Thất bại!",
        "failBody": "Có lỗi xảy ra trong quá trình ứng tuyển.",
        "photoNotice": "Kéo ảnh hoặc click để chọn file",
        "errorServerMessage": "Đã xảy ra lỗi khi nộp đơn ứng tuyển nhanh"
    },
    formLabel: {
        name: "Họ và tên",
        email: "Email",
        phone: "Số điện thoại",
        gender: "Giới tính",
        dob: "Ngày sinh"
    },
    formValidate: {
        name: "Tên của bạn ít nhất phải 2 kí tự",
        dob: "Không được để trống ngày sinh",
        gender: "Không được để trống giới tính",
        phoneAndEmail: "Không đúng định dạng",
        notRequire: "Không bắt buộc"
    }
}

// {
//     layout: {
//         "vi-VN": {
//             title: "Layout",
//             content: "Layout content",
//         },
//         "en-US": {
//             title: "Layout",
//             content: "Layout content",
//         }
//     }X-LANGUAGE
// }