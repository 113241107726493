import { useWindowSize } from '@/hooks';
import { playerObject } from '@/services/constants';
import '@/styles/globals.css';
import { Group, MantineProvider, Stack, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import {
  ContextModalProps,
  ModalsProvider,
  closeAllModals,
} from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { appWithTranslation } from 'next-i18next';
import useTrans from '@/hooks/useTrans';

const Player = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((m) => m.Player),
  { ssr: false }
);

function App({ Component, pageProps }: AppProps) {
  const trans = useTrans();
  const ref = useClickOutside(closeAllModals);

  console.log('Build - 20241009-ver1');
  console.log(`url ${process.env.NEXT_PUBLIC_API_URL}`);
  console.log(`basePath ${process.env.BASE_PATH}`);

  const windowDimension = useWindowSize();
  const divider =
    windowDimension.width > 1440 ? 1 : 1440 / windowDimension.width;

  const successModal = ({
    context,
    id,
    innerProps,
  }: ContextModalProps<{
    text: string;
    content: string;
    code: string;
    color: string;
  }>) => (
    <>
      <Group noWrap ref={ref} h={400} position='center'>
        {windowDimension.width > 500 ? (
          <Player
            style={{ width: 400 / divider }}
            src={playerObject}
            autoplay
            keepLastFrame={true}
          />
        ) : (
          <></>
        )}

        <Stack spacing={20}>
          <Stack spacing={10}>
            <Text
              fw={700}
              fz={windowDimension.width > 1300 ? 26 : 20}
              c={innerProps.color}
            >
              {innerProps.text}
            </Text>
            {/* {windowDimension.width > 1200 ? (
              <Text fz={20 / divider}>{innerProps.content}</Text>
            ) : (
              <></>
            )} */}
          </Stack>

          {innerProps.code != null || innerProps.code != undefined ? (
            <Stack spacing={10}>
              <Text fz={20}>{trans.modalApplyNow.searchCode}</Text>
              <Text fz={30} fw={700} c={innerProps.color} align='center'>
                {innerProps.code}
              </Text>
              {windowDimension.width > 1200 ? (
                <Text fz={20 / divider} color={'red'}>
                  {trans.modalApplyNow.searchCodeBody}
                </Text>
              ) : (
                <></>
              )}

              <Group spacing={5}>
                <Text fz={20}>{trans.modalApplyNow.research}</Text>
                <Link href={'/research'} target={'_blank'}>
                  <Text fz={20} c={innerProps.color}>
                    {trans.modalApplyNow.here}
                  </Text>
                </Link>
                <Text fz={20}>{trans.modalApplyNow.researchBody}</Text>
              </Group>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </Group>
    </>
  );
  return (
    <MantineProvider
      theme={{
        globalStyles: (theme) => ({ body: { margin: '0 auto' } }),
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <ModalsProvider
        modalProps={{ size: '75%', radius: 32, withCloseButton: false }}
        modals={{ success: successModal }}
      >
        <NotificationsProvider position='top-right' zIndex={2077}>
          <Component {...pageProps} />
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default appWithTranslation(App);
