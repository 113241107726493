import { showNotification } from "@mantine/notifications";

export interface ResponseFormDynamicApi {
  isError: boolean;
  data: any;
  errorCode: string | null;
  message: string;
}

export enum LocalStorageKey {
  TENANT = "tenantPortalKey",
  FILE_URL = "fileUrl",
  USE_FILE_URL_CONFIG = "useFileUrlConfig",
  NEWS_DATA = "newsData",
  ARTICLE_URL = "articleUrl",
  ARTICLE_QUERY = "articleQuery",
}

export const getResponseFormDynamicApi = (
  isError: boolean,
  errorCode: string | null,
  data: any,
  message: string
): ResponseFormDynamicApi => {
  return {
    isError: isError,
    data: data,
    message: message,
    errorCode: errorCode,
  };
};

export const showFailureNotification = (errorMessage = "", errorCode = "") => {
  console.log(`Error ${errorCode}: ${errorMessage}`);
  showNotification({
    id: "noti-fail",
    loading: false,
    color: "red",
    title: "Đã xảy ra lỗi",
    message: errorMessage,
    autoClose: 3000,
  });
};

interface FabButton {
  display: string;
  options: { backgroundColor: string; href: string; icon: string };
}

export interface LayoutConfig {
  noDataText: string;
  logoHref: string;
  logo: string;
  favicon: string;
  fileUrl?: string;
  defaultImage: string | undefined;
  defaultHref: string;
  defaultLanguage: string;
  primaryColor: string;
  secondaryColor: string;
  hoverColor: string;
  focusColor: string;
  background: string;
  fabButton: FabButton;
  jsonataJobDetailPage?: string;
  siteName?: string;
  jsonataNewsDetailPage?: string;
  collectForm?: {
    title?: string;
    description?: string;
  }
  colorAlts?: { key: string; value: string }[];
  isHeaderSticky?: boolean;
  logoSize?: { width?: number; height?: number };
  jobDetailPageDescription?: { key: string; label: string; value?: string }[];
  jobDetailPageListDescription?: {
    key: string;
    icon: {
      name: string;
      color?: string;
      stroke?: string;
    };
    cols?: number;
  }[];
  useDefault?: boolean;
  article?: {
    url?: string;
    query?: string;
  };
  titleHead?: string;
}

export interface LayoutDataPage {
  url: string;
  config: LayoutConfig;
  headers: Array<{ sort: number; title: string; href: string; status: string }>;
  page: any;
  footers: { display: string; logo: string; data: any; description: string };
  tenant: string;
}

export interface ReturnLayoutData {
  layout: {
    config: LayoutConfig;
    headers: Array<{
      sort: number;
      title: string;
      href: string;
      status: string;
    }>;
    pages: any;
    footers: { display: string; logo: string; data: any; description: string };
  };
  tenant?: string;
}

export enum ScheduleMethodEnum {
  Onsite = "on-site",
  Offline = "offline",
  Online = "online",
  Video = "video",
  Phone = "phone",
  Others = "others",
}

export enum RecruitmentStageEnum {
  SCREEN_CV = "ScreenCV",
  POTENTIAL_CV = "ApproveInterview",
  TALENT_UPDATE_INFO = "TalentUpdating",
  OFFER = "Offer",
  EXAMINATION = "Examination",
  INTERVIEW = "Interview",
}

export enum ScheduleStatusEnum {
  PENDING = "pending",
  DONE = "done",
  CANCELLED = "canceled",
  HAPPENING = "happening",
  APPROVAL = "approval",
}

export enum TalentScheduleStatusEnum {
  REJECTED = "rejected",
  ACCEPTED = "accepted",
  IN_PROGRESS = "in-progress",
}

interface RecruitmentStage {
  name: string;
  type: RecruitmentStageEnum;
}

interface Schedule {
  currentStage: number;
  startTime: string;
  endTime: string;
  information: string;
  status: ScheduleStatusEnum;
  location: string;
  scheduleMethod: ScheduleMethodEnum;
  scheduleType: RecruitmentStageEnum.EXAMINATION | RecruitmentStageEnum.INTERVIEW;
  schedulesGroup: string;
  mappedScheduleMethod?: string;
  mappedLocation?: string;

  title: string;
  _id: string;
}

export enum OfferStatusEnum {
  REJECTED = "rejected",
  APPROVED = "approved",
  IN_PROGRESS = "in-progress",
}

interface OfferObject {
  status: string;
}

export interface TalentSchedule {
  currentReason?: string;
  currentStage: number;
  mappedCurrentStage: number;
  histories: { fromStage: number }[];
  recruitmentPlan: {
    beginningStages: RecruitmentStage[];
    endingStages: RecruitmentStage[];
    stages: RecruitmentStage[];
  };
  mappedRecruitmentPlanStages: {
    stage: RecruitmentStage;
    schedules: Schedule[];
  }[];

  status: TalentScheduleStatusEnum;
  offer?: OfferObject;
  mappedOfferStatus?: OfferStatusEnum;
  newSchedules: Schedule[];
  talent: {
    basics: {
      dob: string;
      email: string;
      gender: string;
      image: string;
      name: string;
      phone: string;
    };
    _id: string;
  };
  _id: string;
}

export enum FormTemplateFieldTypeBackEnd {
  TEXT = "text",
  USERS = "users",
  SELECT = "select",
  DATE_RANGE = "dateRange",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  TEXTAREA = "textarea",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
  FILE = "file",
  CASCADER = "cascader",
  PARAGRAPH = "paragraph",
  GROUP = "group",
  ARRAY = "array",
  DROP_ZONE = "dropZone",
}

export enum FormSectionStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export const setRootFunctions = (config: any) => {
  const root = document.documentElement;
  root.style.setProperty("--primaryColor", config.primaryColor ? config.primaryColor : "#fff");

  if (Array.isArray(config.colorAlts)) {
    config.colorAlts.forEach((color: { key?: string; value?: string }) => {
      if (color.key && color.value) {
        root.style.setProperty("--" + color.key, color.value);
      }
    });
  }

  root.style.setProperty("--primaryColor", config.primaryColor ? config.primaryColor : "#fff");
  root.style.setProperty("--secondaryColor", config.secondaryColor ? config.secondaryColor : "#fff");
  root.style.setProperty("--hoverColor", config.hoverColor ? config.hoverColor : "#fff");
  root.style.setProperty("--focusColor", config.focusColor ? config.focusColor : "#fff");
  root.style.setProperty("--dynamicFormTextColor", config.dynamicFormTextColor || config.primaryColor || "#fff");
  root.style.setProperty("--boxShadowColor", config.boxShadowColor || "#fff");
  root.style.setProperty("--lightBoxShadowColor", config.lightBoxShadowColor || "#fff");
  root.style.setProperty("--primaryBasedBackground", config.primaryBasedBackground || "#fff");
  root.style.setProperty("--textHeading", config.textHeading || "#232323");
  root.style.setProperty("--textDefault", config.textDefault || "hsla(241, 47%, 15%, 0.8)");
  root.style.setProperty("width", "100%");
};

export const playerObject = {
  v: "4.8.0",
  meta: {
    g: "LottieFiles AE 1.0.0",
    a: "",
    k: "",
    d: "",
    tc: "#FFFFFF",
  },
  fr: 30,
  ip: 0,
  op: 75,
  w: 1500,
  h: 1500,
  nm: "message_sent",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 4",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [750, 750, 0],
              ix: 2,
            },
            a: {
              a: 0,
              k: [19, 46, 0],
              ix: 1,
            },
            s: {
              a: 0,
              k: [124, 124, 100],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [475.5, -168],
                        [-275.403, 166.387],
                        [-240.194, 386.403],
                        [-219, 240],
                        [-123.565, 296.419],
                        [-216, 223],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.070588235294, 0.533333333333, 0.772549019608, 1],
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 190.19019019019,
          st: 10.01001001001,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 3",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [750, 750, 0],
              ix: 2,
            },
            a: {
              a: 0,
              k: [19, 46, 0],
              ix: 1,
            },
            s: {
              a: 0,
              k: [124, 124, 100],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [488, -174],
                        [-460, 16],
                        [-276, 166],
                        [-240, 386],
                        [-122, 296],
                        [4, 404],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.239215686275, 0.741176470588, 1, 1],
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 190.19019019019,
          st: 10.01001001001,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape Layer 4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 50.045,
              s: [100],
            },
            {
              t: 60.0546875,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 45.04,
              s: [1246, 282, 0],
              to: [-3.667, 52, 0],
              ti: [3.667, -52, 0],
            },
            {
              t: 60.0546875,
              s: [1224, 594, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 45.04,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 50.045,
              s: [4, 4, 100],
            },
            {
              t: 60.0546875,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 0,
                k: [1500, 1500],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.239215671315, 0.740438184551, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 240,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.070588235294, 0.533333333333, 0.772549079446, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 50.045,
              s: [100],
            },
            {
              t: 60.0546875,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 45.04,
              s: [1246, 282, 0],
              to: [20, -19.333, 0],
              ti: [-20, 19.333, 0],
            },
            {
              t: 60.0546875,
              s: [1366, 166, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 45.04,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 50.045,
              s: [4, 4, 100],
            },
            {
              t: 60.0546875,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 0,
                k: [1500, 1500],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.239215671315, 0.740438184551, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 240,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.070588235294, 0.533333333333, 0.772549079446, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 50.045,
              s: [100],
            },
            {
              t: 60.0546875,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 45.04,
              s: [1246, 282, 0],
              to: [-55.833, 11.333, 0],
              ti: [55.833, -11.333, 0],
            },
            {
              t: 60.0546875,
              s: [911, 350, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 45.04,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 50.045,
              s: [4, 4, 100],
            },
            {
              t: 60.0546875,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 0,
                k: [1500, 1500],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.239215671315, 0.740438184551, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 45.04,
                    s: [240],
                  },
                  {
                    t: 60.0546875,
                    s: [240],
                  },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.070588235294, 0.533333333333, 0.772549079446, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "New_Plane",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 45.03,
              s: [100],
            },
            {
              t: 49.0341796875,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.832],
                y: [0.461],
              },
              o: {
                x: [0.621],
                y: [0],
              },
              t: 24.009,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.861],
              },
              o: {
                x: [0.413],
                y: [0.079],
              },
              t: 32.018,
              s: [-15.977],
            },
            {
              i: {
                x: [0.705],
                y: [0.886],
              },
              o: {
                x: [0.167],
                y: [0.244],
              },
              t: 40.025,
              s: [-283.577],
            },
            {
              i: {
                x: [0.757],
                y: [1],
              },
              o: {
                x: [0.413],
                y: [-6.015],
              },
              t: 45.03,
              s: [-379.036],
            },
            {
              t: 49.0341796875,
              s: [-377],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.829,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24.009,
              s: [615, 880, 0],
              to: [0, 0, 0],
              ti: [98.064, 301.65, 0],
            },
            {
              i: {
                x: 0.833,
                y: 0.899,
              },
              o: {
                x: 0.167,
                y: 0.081,
              },
              t: 35.021,
              s: [1190, 405, 0],
              to: [-72.384, -287.264, 0],
              ti: [-26.681, -317.47, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0.197,
              },
              t: 40.025,
              s: [658.668, 461.908, 0],
              to: [75.392, 238.957, 0],
              ti: [-189.966, 287.905, 0],
            },
            {
              t: 49.0341796875,
              s: [1200, 320, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 15,
              s: [564, 826, 0],
              to: [29.5, -10.167, 0],
              ti: [-13, 6.667, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 24.009,
              s: [741, 765, 0],
              to: [13, -6.667, 0],
              ti: [16.5, -3.5, 0],
            },
            {
              t: 31.0166015625,
              s: [642, 786, 0],
            },
          ],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.64, 0.64, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.145, 0.145, 0.167],
                y: [0.231, 0.231, 0],
              },
              t: 15,
              s: [80, 80, 100],
            },
            {
              i: {
                x: [0.376, 0.376, 0.833],
                y: [0.139, 0.139, 1],
              },
              o: {
                x: [0.359, 0.359, 0.167],
                y: [0, 0, 0],
              },
              t: 21.006,
              s: [100, 100, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.127, 0.127, 0.167],
                y: [0.214, 0.214, 0],
              },
              t: 27.013,
              s: [80, 80, 100],
            },
            {
              t: 49.0341796875,
              s: [20, 20, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      w: 1500,
      h: 1500,
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 2",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [700, 900, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-300, -124],
                    [-65, 67],
                    [395, -459],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 0,
                k: 0,
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.174],
                      y: [0.832],
                    },
                    o: {
                      x: [0.337],
                      y: [0],
                    },
                    t: 40.035,
                    s: [0],
                  },
                  {
                    t: 62,
                    s: [82],
                  },
                ],
                ix: 2,
              },
              o: {
                a: 0,
                k: 0,
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.239215671315, 0.740438184551, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 88,
                ix: 5,
              },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [8, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [708, 900, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-300, -124],
                    [-65, 67],
                    [395, -459],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 0,
                k: 0,
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: 40.035,
                    s: [0],
                  },
                  {
                    t: 51.0458984375,
                    s: [96],
                  },
                ],
                ix: 2,
              },
              o: {
                a: 0,
                k: 0,
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.06968089085, 0.532746857288, 0.772549019608, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 88,
                ix: 5,
              },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 5,
      bm: 0,
    },
  ],
  markers: [],
};

export const newTenants = ["shlv", "vietbank", "tanlong", "unibenfoods", "gelex", "tanlong-prod", "svfc", "decathlon", "dxg"];
export const applicationCollectFormTenants = ["svfc"];
export const defaultNewsTenants = ["vietbank", "demo"];
