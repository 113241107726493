// eslint-disable-next-line import/no-anonymous-default-export
export default {
    home: {
        title: 'Hello Viblo!',
        content: 'Bắt đầu một bài viết nào',
    },
    "all": "All",
    "applyFor": "Apply for",
    "apply": "Apply",
    "searchResult": "Search result",
    "relatedJobs": "Related jobs",
    "benefits": "Benefits",
    "assignTo": "Assign to",
    "noInfo": "No information",
    "generalInfo": "General information",
    "hotJob": "Hot job",
    "filterByCriteria": "Filter by criteria",
    "jobName": "Job name",
    "jobLocation": "Job location",
    "salary": "Negotiable",
    "job": "jobs",
    "jobListTitle": "Job list",
    modalApplyNow: {
        "searchCode": "Your candidate search code is:",
        "searchCodeBody": "Please save it to avoid losing the search code",
        "research": "Reseach",
        "here": "here",
        "researchBody": "to track application progress"
    },
    applyNow: {
        "success": "Success!",
        "successBody": "Thank you for your interest in the company.",
        "fail": "Failed!",
        "failBody": "An error occurred during the application process.",
        "photoNotice": "Drag image or click to select file",
        "errorServerMessage": "An error occurred during the application process."
    },
    formLabel: {
        name: "Name",
        email: "Email",
        phone: "Phone",
        gender: "Gender",
        dob: "Date of birth"
    },
    formValidate: {
        name: "Your name must be at least 2 characters",
        dob: "Date of birth is required",
        gender: "Gender is required",
        phoneAndEmail: "Invalid format",
        notRequire: "Not required"
    }
}